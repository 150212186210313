<template>
  <div class="h-full cust_card p-2 relative grid lg:grid-cols-2 gap-4">
    <div class=" rounded py-2 pt-0 h-full">
      <div class="">
        <div class="flex items-center justify-between">
          <div class="heading-3 text-text1" :class="mobileView ? 'pb-2' : ''">● Text Templates</div>
          <div class="flex flex-wrap items-baseline justify-between py-2">
            <div class="pl-2">
                <Button @buttonAction="addNewStaff()" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Template'"/>
              </div>
          </div>
        </div>
      </div>
      <div>
        <div :style="`min-height:` +  cardHeight + `px;`" class="mt-2">
          <div class="bg-white rounded cust_card p-4">
            <div class="" v-if="textTemplateList.length > 0">
              <div>
                <draggable 
                  tag="ul"
                  v-bind="dragOptions"
                  :list="textTemplateList" 
                  group="people" 
                  @start="drag = true" 
                  @end="dragArray" 
                  id='list'
                >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <div v-for="data in textTemplateList" :key="data.templateId">
                    <div class="flex items-center ">
                      <span v-if="data.isTemplateActive" ><i :class="data.isTemplateActive ? 'cursor-pointer' : ''"
                        class="text-gray3 pr-4  fas fa-grip-vertical"
                      ></i></span>
                      <div 
                        @click.stop="staffDetail(data.templateId)"  
                        :class="`${!data.isTemplateActive ? 'line-through text-error' : 'text-primary'} ${data.isTemplateActive ? 'cursor-pointer' : 'cursor-pointer'} ${mobileView ? ' heading-6' : ' heading-5'}`" 
                        class="text-left mb-0 font-semibold p-1"
                      >
                        {{ data.templateName }}
                      </div>
                    </div>
                  </div>
                </transition-group>
                </draggable>
              </div>
            </div>
            <div v-else>
              <div class="flex p-3 text-sm text-primary bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No template found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  
</div>
</template>
<script>
import draggable from 'vuedraggable'
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    draggable
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      drag: false,
      textTemplateList: [],
      cardHeight: 0,
      mobileView: false,
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    document.title = 'Text Templates'
    this.getTwilioListing()
  },
  watch: {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    getTwilioListing () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.SmsTemplateList(
        response => {
          let tempData = response.Data !== null ? response.Data  : null
          if (response.Data !== null) {
            this.textTemplateList = tempData.slice().sort(this.sortByIsActive);
          } else {
            this.textTemplateList = tempData
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    sortByIsActive(obj1, obj2) {
      // Sort by isTemplateActive in descending order (true first, false later)
      return obj2.isTemplateActive - obj1.isTemplateActive;
    },
    dragArray () {
      this.drag = false
      ADMINAPI.smsTemplateReOrder(
        this.textTemplateList,
        () => {
          this.getTwilioListing()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    addNewStaff () {
      this.$router.push({name: 'AddTemplate', params: {templateId: 0}})
    },
    staffDetail (data) {
      console.log('data', data)
      this.$router.push({name: 'EditTemplate', params: {templateId: data}})
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}


.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb6f;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
